<template>
  <section class="search">
    <v-container  grid-list-md text-center id="news_top" style="margin-bottom:10px">
      <template>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mt-4
        mb-12
      >
        <v-layout wrap>
          <v-flex
            v-for="(item, i) in lists"
            :key="i"
            xs12
            pa-2
            class="md3"
          >
            <v-card
              class="mx-auto search-card"
            >
              <a :href="item.url">
                <div class="search-div">
                  <p class="search-title">{{item.title}}</p>
                  <h3 class="search-type">{{item.label}}</h3>
                </div>
              </a>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    page: 1,
    totalPage: 1,
    keywords: '',
    lists: [],
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '搜索', disabled: true }
    ]
  }),
  created () {
    this.keywords = this.getUrlKey('keywords')
    this.breadcrumbs.push({ text: this.keywords, disabled: true })
    this.search()
  },
  watch: {
    page: {
      handler: function (val, oldVal) {
        this.page = val
        this.search()
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    search () {
      this.https.get('search', { keywords: this.keywords, page: this.page }).then(response => {
        if (response.code === 0) {
          this.lists = response.data.data
          this.totalPage = response.data.totalPage
        }
      })
    },
    getUrlKey (name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [ , ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    }
  }
}
</script>
<style>
  .search-card {
    border: none !important;
    box-shadow: none !important;
  }
  .search-div {
    position: relative;
    padding: 20px 20px 40px 20px;
    text-align: left;
  }
  .search-title {
    width:100%;
    color: #333;
    font-size: 16px;
    height: 90px;
    border-bottom: solid 1px #E0E0E0;
  }
  .search-type {
    color: #999;
    font-size: 16px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    box-shadow: none !important;
  }
  .search-card :hover {
    box-shadow:0 4px 10px 0 rgba(0,0,0,0.08);
  }
  .search-card:hover .search-title {
    box-shadow: none !important;
    color: #C70805;
  }
  .search-card:hover .search-type {
    color: #666;
  }
</style>
